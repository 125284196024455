<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Module-Groups</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table"
        >
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: item, getItens: getItens, jsonData: false }" />
            </template>

            <!-- <template v-slot:header="{ items }">
              <thead v-for="item in items" :key="item.id">
                <tr>
                  <th>{{items}}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
            </template> -->

            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.date }}</td>
                  <!-- <td>{{ item.situacao }}</td> -->
                  <td class="text-right">
                    <v-btn
                      v-if="permiteAcao($route, 'edit')"
                      small
                      @click="editItem(item)"
                      tile
                      outlined 
                      :color="variables.colorPrimary"
                      class="mr-2"
                    >
                      Editar
                    </v-btn>

                    <v-btn
                      v-if="permiteAcao($route, 'delete')"
                      small
                      @click="deleteItem(item)"
                      tile
                      outlined 
                      :color="variables.colorError"
                    >
                      Excluir
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import listagemTables from '@/views/mixins/listagemTables'
// import Alert from 'sweetalert2'

export default {
  name: 'Cadastro',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    search: '',
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('grupos', ['listaItens', 'totalItens', 'item']),
    ...mapGetters('roles', ['permiteAcao']),
    variables: () => variables
  },

  methods: {
    ...mapActions('grupos', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens']),
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
